import './index.css'
import { useState } from "react"
import { Container, Row, Col, Navbar, NavbarToggler, Collapse, Nav, NavItem, NavLink } from "reactstrap"
import { isAuthenticated } from "./../../../services/auth"
import { ArrowRight } from 'react-feather'
import { LinkButton } from '../../../components/Button/LinkButton'
import { INSTRAGRAM_LINK, TERMS_URL, WHATSAPP_CONTACT_LINK, YOUTUBE_LINK } from '../../../strings'

import header_bg_left from './../../../assets/images/home_bg_left.svg'
import header_bg_right from './../../../assets/images/home_bg_right.svg'
import header_phone_image from './../../../assets/images/home_phone.png'
import aloha_logo from './../../../assets/images/aloha-130.png'
import badge_app_store from './../../../assets/images/badge-app-store.png'
import badge_google_play from './../../../assets/images/badge-google-play.png'
import Instagram from "../../../components/Icons/Instagram"
import Whatsapp from "../../../components/Icons/Whatsapp"
import YouTube from "../../../components/Icons/YouTube"
import HeroImage from './../../../assets/images/hero_image.jpg'
import Content2Image from './../../../assets/images/content2_image.jpg'
import Content3Image from './../../../assets/images/content3_image.jpg'

const APPLE_STORE_LINK = "https://apps.apple.com/br/app/aloha/id1638548450"
const GOOGLE_PLAY_LINK = "https://play.google.com/store/apps/details?id=com.appaloha.app"


/* Local components */

const TopNavBar = ({ isNavOpen, navToggle }) => {
    return (
        <Navbar expand="md">
            <NavbarToggler onClick={navToggle} />
            <Collapse className="mt-2 mt-md-0 justify-content-center" isOpen={isNavOpen} navbar>
                <Nav navbar>
                    <NavItem className="px-4">
                        <NavLink href="#">Quem somos</NavLink>
                    </NavItem>
                    <NavItem className="px-4">
                        <NavLink href="#">Como funciona</NavLink>
                    </NavItem>
                    {isAuthenticated() ? (
                        <NavItem className="px-4">
                            <NavLink href="/dashboard/home">Dashboard</NavLink>
                        </NavItem>
                    ) : (
                        <NavItem className="px-4">
                            <NavLink href="/login">Entre ou Cadastre-se</NavLink>
                        </NavItem>
                    )}
                </Nav>
            </Collapse>
        </Navbar>
    )
}

const Section = (props) => {
    return (
        <section {...props} className='py-0 pb-3 py-md-5' style={{ marginBottom: 90 }}>
            {props.children}
        </section>
    )
}

const SectionContent = (props) => {
    let lefSpaceClass = props.addleftspace ? 'ms-0 ms-md-4' : ''
    let defaultClass = `px-2 px-md-0 `

    return (
        <div {...props} className={defaultClass + lefSpaceClass}>
            {props.children}
        </div>
    )
}

const KnowMoreTextLink = ({ hide_arrow }) => {
    return (
        <LinkButton href={WHATSAPP_CONTACT_LINK} className="d-flex gap-2 align-items-center fw-bold text-subtitle1 text-primary">
            Saiba mais {hide_arrow ? <></> : <ArrowRight />}
        </LinkButton>
    )
}


/* Page Sections */

const SectionHero = () => (
    <Section>
        <Row className='m-0 align-items-center'>
            <Col sm={12} md={6} className='p-0 order-md-1 mb-4 mb-md-0'>
                <div className="section-image-container">
                    <img className='section-image rounded-0 d-block d-lg-none' style={{ objectFit: 'cover' }} height={300} src={HeroImage} alt='hero' />
                    <img className='section-image d-none d-lg-block' style={{ objectFit: 'cover' }} width={550} height={450} src={HeroImage} alt='hero' />
                </div>
            </Col>
            <Col sm={12} md={6}>
                <SectionContent>
                    <h2 className='text-h4 fw-bold mb-4'>Aumente a receita e ofereça ao seus clientes uma experiência encantadora!</h2>
                    <p className='text-subtitle1 mb-4'>Uma solução completa para aprimorar a estadia dos seus clientes e impulsionar o sucesso do seu negócio</p>
                    <KnowMoreTextLink hide_arrow={'true'} />
                </SectionContent>
            </Col>
        </Row>
    </Section>
)

const SectionFeatures = () => {
    return (
        <Section>
            <Row className='m-0'>
                <Col sm={12} md={4} className='text-center mb-4 mb-md-3'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="55" viewBox="0 0 56 55" fill="none">
                        <circle cx="28.3335" cy="27.5" r="27.5" fill="#285A9F" fillOpacity="0.1" />
                        <path d="M24.9002 29.2667L26.0668 25.4667L23.0002 23.0001H26.8002L28.0002 19.2667L29.2002 23.0001H33.0002L29.9002 25.4667L31.0668 29.2667L28.0002 26.9001L24.9002 29.2667ZM20.0002 41.6667V31.3667C19.1557 30.4334 18.5002 29.3667 18.0335 28.1667C17.5668 26.9667 17.3335 25.689 17.3335 24.3334C17.3335 21.3556 18.3668 18.8334 20.4335 16.7667C22.5002 14.7001 25.0224 13.6667 28.0002 13.6667C30.9779 13.6667 33.5002 14.7001 35.5668 16.7667C37.6335 18.8334 38.6668 21.3556 38.6668 24.3334C38.6668 25.689 38.4335 26.9667 37.9668 28.1667C37.5002 29.3667 36.8446 30.4334 36.0002 31.3667V41.6667L28.0002 39.0001L20.0002 41.6667ZM28.0002 32.3334C30.2224 32.3334 32.1113 31.5556 33.6668 30.0001C35.2224 28.4445 36.0002 26.5556 36.0002 24.3334C36.0002 22.1112 35.2224 20.2223 33.6668 18.6667C32.1113 17.1112 30.2224 16.3334 28.0002 16.3334C25.7779 16.3334 23.8891 17.1112 22.3335 18.6667C20.7779 20.2223 20.0002 22.1112 20.0002 24.3334C20.0002 26.5556 20.7779 28.4445 22.3335 30.0001C23.8891 31.5556 25.7779 32.3334 28.0002 32.3334ZM22.6668 37.7001L28.0002 36.3334L33.3335 37.7001V33.5667C32.5557 34.0112 31.7168 34.3612 30.8168 34.6167C29.9168 34.8723 28.9779 35.0001 28.0002 35.0001C27.0224 35.0001 26.0835 34.8723 25.1835 34.6167C24.2835 34.3612 23.4446 34.0112 22.6668 33.5667V37.7001Z" fill="#285A9F" />
                    </svg>
                    <h4 className='my-3 text-h6 fw-bold'>Experiência única</h4>
                    <p className='mx-auto text-subtitle1' style={{ maxWidth: 320 }}>Permita que seus hóspedes tenham uma estadia inovadora em seu hotel, desde o momento da reserva até o check-out.</p>
                </Col>
                <Col sm={12} md={4} className='text-center mb-4 mb-md-3'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="55" viewBox="0 0 56 55" fill="none">
                        <circle cx="28" cy="27.5" r="27.5" fill="#285A9F" fillOpacity="0.1" />
                        <path d="M20.8334 35.585C20.4556 35.585 20.139 35.4573 19.8834 35.2017C19.6279 34.9462 19.5001 34.6295 19.5001 34.2517V31.585H36.8334V19.585H39.5001C39.8779 19.585 40.1945 19.7128 40.4501 19.9684C40.7056 20.2239 40.8334 20.5406 40.8334 20.9184V40.9184L35.5001 35.585H20.8334ZM14.1667 34.2517V15.585C14.1667 15.2073 14.2945 14.8906 14.5501 14.635C14.8056 14.3795 15.1223 14.2517 15.5001 14.2517H32.8334C33.2112 14.2517 33.5279 14.3795 33.7834 14.635C34.039 14.8906 34.1667 15.2073 34.1667 15.585V27.585C34.1667 27.9628 34.039 28.2795 33.7834 28.535C33.5279 28.7906 33.2112 28.9184 32.8334 28.9184H19.5001L14.1667 34.2517ZM31.5001 26.2517V16.9184H16.8334V26.2517H31.5001Z" fill="#285A9F" />
                    </svg>
                    <h4 className='my-3 text-h6 fw-bold'>Comunicação eficaz</h4>
                    <p className='mx-auto text-subtitle1' style={{ maxWidth: 320 }}>Mantenha seus hóspedes informados sobre eventos, promoções e serviços do hotel aumentando o engajamento e a satisfação.</p>
                </Col>
                <Col sm={12} md={4} className='text-center'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="55" viewBox="0 0 56 55" fill="none">
                        <circle cx="27.6667" cy="27.5" r="27.5" fill="#285A9F" fillOpacity="0.1" />
                        <path d="M30.1668 40.1184C29.6557 40.6295 29.0224 40.885 28.2668 40.885C27.5113 40.885 26.8779 40.6295 26.3668 40.1184L14.6335 28.385C14.3891 28.1406 14.1946 27.8517 14.0502 27.5184C13.9057 27.185 13.8335 26.8295 13.8335 26.4517V16.9184C13.8335 16.185 14.0946 15.5573 14.6168 15.035C15.1391 14.5128 15.7668 14.2517 16.5002 14.2517H26.0335C26.4113 14.2517 26.7668 14.3239 27.1002 14.4684C27.4335 14.6128 27.7224 14.8073 27.9668 15.0517L39.7002 26.8184C40.2113 27.3295 40.4668 27.9573 40.4668 28.7017C40.4668 29.4462 40.2113 30.0739 39.7002 30.585L30.1668 40.1184ZM28.2668 38.2517L37.8002 28.7184L26.0335 16.9184H16.5002V26.4517L28.2668 38.2517ZM19.8335 22.2517C20.3891 22.2517 20.8613 22.0573 21.2502 21.6684C21.6391 21.2795 21.8335 20.8073 21.8335 20.2517C21.8335 19.6962 21.6391 19.2239 21.2502 18.835C20.8613 18.4462 20.3891 18.2517 19.8335 18.2517C19.2779 18.2517 18.8057 18.4462 18.4168 18.835C18.0279 19.2239 17.8335 19.6962 17.8335 20.2517C17.8335 20.8073 18.0279 21.2795 18.4168 21.6684C18.8057 22.0573 19.2779 22.2517 19.8335 22.2517Z" fill="#285A9F" />
                    </svg>
                    <h4 className='my-3 text-h6 fw-bold'>Aumente as vendas</h4>
                    <p className='mx-auto text-subtitle1' style={{ maxWidth: 320 }}>Ofereça serviços adicionais, upgrades e pacotes exclusivos diretamente aos seus hóspedes, aumentando a receita do seu hotel</p>
                </Col>
            </Row>
        </Section>
    )
}

const SectionContent1 = () => (
    <Section>
        <Row className='m-0'>
            <Col sm={12} md={6} className='p-0 mb-4 mb-md-0 order-md-1'>
                <div className="section-image-container p-4">
                    <img className='section-image' src={header_phone_image} alt='app screen' />
                </div>
            </Col>
            <Col sm={12} md={6}>
                <SectionContent>
                    <h3 className='mb-4 text-h4 fw-bold'>Um aplicativo completo, rápido e acessível</h3>
                    <p className='text-subtitle1 mb-3'>Veja algumas das opções disponíveis para seu hóspede:</p>
                    <ol className='text-subtitle1'>
                        <li>Ver cronograma completo do hotel.</li>
                        <li>Acesso ao Wi-Fi diretamente pelo App.</li>
                        <li>Solicitar serviços disponíveis</li>
                        <li>Fazer pedidos de produtos e refeições</li>
                    </ol>
                    <small className="text-center text-md-start d-block px-2 mt-5">Disponível para download</small>
                    <div className="d-flex justify-content-center justify-content-md-start px-2 py-2">
                        <a href={APPLE_STORE_LINK} target="_blank" rel="noopener noreferrer">
                            <img style={{ maxWidth: 175 }} src={badge_app_store} alt="Apple Store" />
                        </a>
                        <a href={GOOGLE_PLAY_LINK} target="_blank" rel="noopener noreferrer" className="ps-2">
                            <img style={{ maxWidth: 175 }} src={badge_google_play} alt="Google Play Store" />
                        </a>
                    </div>
                </SectionContent>
            </Col>
        </Row>
    </Section>
)

const SectionContent2 = () => (
    <Section>
        <Row className='m-0'>
            <Col sm={12} md={6} className='px-0 mb-4 mb-md-0'>
                <div className="section-image-container">
                    <img className='section-image' src={Content2Image} alt="content section 2" />
                </div>
            </Col>
            <Col sm={12} md={6}>
                <SectionContent addleftspace={'true'}>
                    <h3 className='mb-4 text-h4 fw-bold'>Transforme seu hotel e encante seus hóspedes</h3>
                    <p className='text-subtitle1 mb-3'>Seja o líder da indústria hoteleira, oferecendo uma experiência de hospedagem que vai além das expectativas. Seu sucesso começa aqui!</p>
                    <KnowMoreTextLink />
                </SectionContent>
            </Col>
        </Row>
    </Section>
)

const SectionContent3 = () => (
    <Section>
        <Row className='m-0'>
            <Col sm={12} md={6} className='px-0 mb-4 mb-md-0 order-md-1'>
                <div className="section-image-container">
                    <img className='section-image' src={Content3Image} alt="content section 3" />
                </div>
            </Col>
            <Col sm={12} md={6} className='pe-md-5'>
                <SectionContent>
                    <h3 className='mb-4 text-h4 fw-bold'>Uma experiência única para seu cliente</h3>
                    <p className='text-subtitle1 mb-3'>Não deixe seu hotel ficar para trás na era digital. Junte-se a nós e ofereça uma experiência de hospedagem incomparável aos seus hóspedes!</p>
                    <p className='text-subtitle1 mb-3'>Entre em contato conosco hoje mesmo para uma demonstração gratuita e descubra como nosso aplicativo pode impulsionar o sucesso do seu hotel.</p>
                    <KnowMoreTextLink />
                </SectionContent>
            </Col>
        </Row>
    </Section>
)

const SectionVideo = () => {
    return (
        <Section>
            <div className='text-center'>
                <h3 className='mb-3 text-h5 fw-bold'>Sobre o Aloha</h3>
                <p className='text-subtitle1 mb-3'>Veja o vídeo abaixo para entender como o Aloha pode te ajudar a impulsionar seu hotel</p>
                <div className='px-3 px-md-0'>
                    <iframe style={{ maxWidth: '100%' }} alt="Aloha Video" title="Aloha Video" className="rounded w-100 ar-16-9" src="https://www.youtube.com/embed/qQOmAZi2slU?rel=0"></iframe>
                </div>
            </div>
        </Section>
    )
}

const SectionFooter = () => {
    return (
        <footer className='mb-5'>
            <Row className='align-items-center'>
                <Col xs={12} md={4}>
                    <div className='text-center'>
                        <img width={55} src={aloha_logo} alt="Logo" />
                        <h6 className='py-2 text-gray1 text-h6'>Aloha</h6>
                        <p className='text-body2 text-gray2 mx-auto' style={{ maxWidth: 300 }}>Junte-se a nós para elevar a experiência hoteleira e alcançar novos patamares de sucesso!</p>
                        <div className="center gap-3 py-3">
                            <a href={INSTRAGRAM_LINK} target="_blank" rel="noopener noreferrer" className="footer-social-link">
                                <Instagram />
                            </a>
                            <a href={WHATSAPP_CONTACT_LINK} target="_blank" rel="noopener noreferrer" className="footer-social-link">
                                <Whatsapp />
                            </a>
                            <a href={YOUTUBE_LINK} target="_blank" rel="noopener noreferrer" className="footer-social-link">
                                <YouTube />
                            </a>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={4}>
                    <div className="mt-2 mt-md-0 text-center">
                        <h6 className='text-body1' style={{ fontWeight: 500 }}>Transparência</h6>
                        <nav className='mt-2'>
                            <ul className='list-unstyled'>
                                <li><a href="#" className='text-gray2 text-body2 text-decoration-none'>Quem somos</a></li>
                                <li><a href={WHATSAPP_CONTACT_LINK} className='text-gray2 text-body2 text-decoration-none' target="_blank" rel="noopener noreferrer">Ajuda e Suporte</a></li>
                                <li><a href={TERMS_URL} className='text-gray2 text-body2 text-decoration-none' target="_blank" rel="noopener noreferrer">Politica de privacidade</a></li>
                                <li><a href="#" className='text-gray2 text-body2 text-decoration-none'>Termos e condições</a></li>
                            </ul>
                        </nav>
                    </div>
                </Col>
                <Col xs={12} md={4}>
                    <div className='text-center'>
                        <h6 className='text-body2' style={{ fontWeight: 500 }}>Baixe o App</h6>
                        <div className="d-flex flex-column align-items-center">
                            <a className='mt-2' href="https://apps.apple.com/br/app/aloha/id1638548450" target="_blank" rel="noopener noreferrer">
                                <img width={175} src={badge_app_store} alt="Apple Store" />
                            </a>
                            <a className='mt-1' href="https://play.google.com/store/apps/details?id=com.appaloha.app" target="_blank" rel="noopener noreferrer">
                                <img width={175} src={badge_google_play} alt="Google Play Store" />
                            </a>
                        </div>
                    </div>
                </Col>
            </Row>
        </footer>
    )
}

/* Page */

const HomePage = () => {

    const [isNavOpen, setIsNavOpen] = useState(false);
    const navToggle = () => setIsNavOpen(!isNavOpen);

    return (
        <Row className='gx-0'>
            <Col>
                <img src={header_bg_left} alt="background left" className="w-100" />
            </Col>
            <Col md="9">
                <TopNavBar isNavOpen={isNavOpen} navToggle={navToggle} />
                <Container className='bg-white px-0' style={{ maxWidth: 1112 }}>
                    <SectionHero />
                    <SectionFeatures />
                    <SectionContent1 />
                    <SectionContent2 />
                    <SectionContent3 />
                    <SectionVideo />
                    <SectionFooter />
                </Container>
            </Col>
            <Col>
                <img src={header_bg_right} alt="background right" className="w-100" />
            </Col>
        </Row>
    )
}

export default HomePage